import React, { useState } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"
import { TwitterTimelineEmbed, 
        TwitterShareButton, 
        TwitterFollowButton, 
        TwitterHashtagButton, 
        TwitterMentionButton, 
        TwitterTweetEmbed, 
        TwitterMomentShare, 
        TwitterDMButton, 
        TwitterVideoEmbed, 
        TwitterOnAirButton 
      } from 'react-twitter-embed'
import {  FaItalic, 
          FaLinkedinIn, 
          FaFacebookF,
          FaTwitter  
} from 'react-icons/fa'
import { IconContext } from "react-icons";
import Img from "gatsby-image"

import Layout from "../components/layout"
import Front from "../components/front"
import Box from "../components/box"
import Image from "../components/image"
import SEO from "../components/seo"
import Banner from "../components/banner"

import "../styles/style.sass"
import "../styles/style.css"
import concept from "../images/HaConcept.png"
import anish from "../images/anish.png"
import vineesh from "../images/vineesh.png"
import radhika from "../images/radhika.png"
import samanyu from "../images/samanyu.png"
import adhila from "../images/adhila.png"

const IndexPage = () => {
  const [Vanimation, Vanimstart] = useState("v elem")
  const [Ianimation, Ianimstart] = useState("i elem")
  const [Banimation, Banimstart] = useState("b elem")
  const [Ganimation, Ganimstart] = useState("g elem")
  const [Yanimation, Yanimstart] = useState("y elem")
  const [Oanimation, Oanimstart] = useState("o elem")
  const [Ranimation, Ranimstart] = useState("r elem")

  const data = useStaticQuery(graphql`
    query {
        concept:file(relativePath: {eq: "concept.png"}) {
            childImageSharp {
                fluid(maxWidth: 400, quality:100) {
                    ...GatsbyImageSharpFluid  
      }
    }
  }
}
  `)



  return(
  <Layout>
    <SEO title="Home" />
    <Front/>
    <div id="concept" className=" concept">
      <h1 style={{ textAlign:"center",
                    margin:"0% 0%",
                    padding: "5% 0%" 
    }} >Core Concepts</h1>
      <div style={{
        margin: "0% 5%", 
        padding: "10% 0%", 
      }}>
        <div className="card">
          <img src={concept}/>
        </div>
        <div style={{ padding: "5% 0% 0% 0%" }} ><h2 style={{width: "100%", padding: "2%"}} >9 out of 10 employees are willing to trade a
  percentage of their lifetime earnings for
  greater meaning at work.</h2>
            <h3 style={{textAlign: 'right',
          fontWeight: 200
          }} >-Harvard Business Review, November 2018</h3>
        </div>    
      </div>  
    </div>
    <div className="rainbow" >
      <div onClick={()=>{Vanimstart((Vanimation === "v elem" )? "elemclick" : "v elem")}} className={Vanimation} >
        <div>
          <h2>COMPETENCE</h2>
          <p>The quality of having sufficient attitude, knowledge,skill and
  intention & ability to upgrade regularly.</p>
        </div>
      </div>
      <div onClick={()=>{Ianimstart((Ianimation === "i elem" )? "elemclick" : "i elem")}} className={Ianimation} >
        <div>
          <h2>CADENCE</h2>
          <p>Enhances predictability of results, and certainty across circles,
  with clear and measurable outcomes.</p>  
        </div>
      </div>
      <div onClick={()=>{Banimstart((Banimation === "b elem" )? "elemclick" : "b elem")}} className={Banimation} >
        <div>
          <h2>CIRCLES</h2>
          <p>Circles help remove bureaucracy. Member holds a specific role in a
  circle and may be part of multiple circles.</p>  
        </div>
      </div>
      <div onClick={()=>{Ganimstart((Ganimation === "g elem" )? "elemclick" : "g elem")}} className={Ganimation} >
        <div>
          <h2>CATALYST</h2>
          <p>Being the torchbearer of the transformation, catalyst acts as an
  agent that enables or speeds significant change.</p>
        </div>  
      </div>
      <div onClick={()=>{Yanimstart((Yanimation === "y elem" )? "elemclick" : "y elem")}} className={Yanimation} >
        <div>
          <h2>CONVERSATIONS</h2>
          <p>Clear and concise communication among all stakeholders to
  ensure that the organisation achieves its desired outcomes.</p>  
        </div>
      </div>
      <div onClick={()=>{Oanimstart((Oanimation === "o elem" )? "elemclick" : "o elem")}} className={Oanimation} >
        <div>
          <h2>CONTEXT</h2>
          <p>Members need to comprehend the circumstances constituting
  the background of an event, challenge, idea or statement to
  make decisions.</p> 
        </div> 
      </div>
      <div onClick={()=>{Ranimstart((Ranimation === "r elem" )? "elemclick" : "r elem")}} className={Ranimation} >
        <div>
          <h2>CLARITY</h2>
          <p>Every organisation exists for a reason(purpose) and the
  organisation thrives when every contributor is aligned to this.</p>  
        </div>
      </div>
    </div>
    <div id="team" className=" team">
      <h1 style={{ textAlign:"center",
                    margin:"5% 0%"
    }} >The Catalysts</h1>
      <div className="deconpancake">
        <div className="panchild">
          <img src={anish}/>
          <h3>Anish Aravind</h3>
        </div>
        <div className="panchild">
          <img src={vineesh}/>
          <h3>Vineesh U S</h3>
        </div>
        <div className="panchild">
          <img src={radhika}/>
          <h3>Radhika Krishnan</h3>
        </div>
        <div className="panchild">
          <img src={samanyu}/>
          <h3>Samanyu Neelson</h3>
        </div>
        {/* <div className="panchild">
          <img src={adhila}/>
          <h3>Adhila Rasheed</h3>
        </div> */}
      </div>
    </div>
    <div id="contact" className="" style= {{ padding: "0% 5%" }} >
      <h1 style={{ textAlign:"center", 
                    padding:"5% 0%",
    }} >Social</h1>
      <div className="layout">
        <div >
          <TwitterTimelineEmbed
            sourceType="profile"
            screenName="hrascatalyst"
            options={{height: 500}}
            noScrollbar = "yes"
          />
        </div>
        <div>
          <h1 style={{ textAlign: "center" }} >Email us at: <a href = "mailto:agile@hrascatalyst.com">agile@hrascatalyst.com</a></h1>
          <div className="Spanel">
            {/* linkedin */}
            <div className= "social" style={{
              backgroundColor: "#006087",
             }}>
              <IconContext.Provider value={{ color: "white",size: "32px", className: "global-class-name" }}>
                <a href="https://www.linkedin.com/company/agile-hr-catalyst/?viewAsMember=true">
                  <FaLinkedinIn />
                </a>
              </IconContext.Provider>
            </div>

            {/* twitter */}
            <div className = "social" style={{
              backgroundColor: "#55ACEE",
             }}>
              <IconContext.Provider value={{ color: "white",size: "30px", className: "global-class-name" }}>
                <a href="https://twitter.com/hrascatalyst">
                  <FaTwitter />
                </a>
              </IconContext.Provider>
            </div>
            {/* facebook */}
            <div className= "social" style={{
              backgroundColor: "#395185",
             }}>
              <IconContext.Provider value={{ color: "white",size: "30px", className: "global-class-name" }}>
                <a href="https://www.facebook.com/agilehrascatalyst">
                  <FaFacebookF />
                </a>
              </IconContext.Provider>
            </div>
          </div>
        </div>
      </div>  
    </div>
  </Layout>
)
}  
export default IndexPage
